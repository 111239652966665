<template>
  <BackgroundPattern
    variant="bottom"
    class="top-20 mx-auto hidden dt:block" />

  <Content class="pt-6 md:flex md:justify-center dt:hidden">
    <PrimaryButton
      :label="t('index.listen-radio')"
      :dark="false"
      right-icon="play-circle-fill"
      class="mb-5 block w-full md:w-auto"
      @click="$player.loadRadio(config.public.radioBoaId)" />
  </Content>

  <section>
    <h2
      class="mx-auto mb-6 dt:mt-12"
      v-html="$t('index.favorite-of-the-moment')" />

    <PodcastCarousel
      :items="promoted"
      class="mx-auto mb-11 w-full max-w-4xl dt:mb-20" />

    <Content class="mb-10 flex justify-center dt:hidden">
      <WhatSongModule />
    </Content>
  </section>

  <section>
    <h3
      class="dt:no-br mx-auto mb-6 dt:mx-6 dt:text-left"
      v-html="$t('index.new-regional-podcasts')" />

    <HomeEpisodeCarousel
      :episodes="episodes"
      class="dt:mb-20" />
  </section>

  <section class="relative z-[1] mb-16">
    <h3
      class="mx-auto mb-6"
      v-html="$t('index.browse-themes')" />

    <CategoryList :categories="themes" />
  </section>

  <section class="mb-24 pt-10">
    <WhatWouldLikeListenSection />
  </section>

  <section class="mb-4">
    <h3
      class="relative z-[1] mx-auto mb-6 text-center dt:mx-6 dt:text-left"
      v-html="$t('index.our-last-news')" />

    <Content>
      <div class="relative">
        <Carousel
          :elements="news"
          :elements-per-page="1.2"
          :elements-per-page-desktop="3.5"
          :end-padding="true"
          :end-padding-desktop="true"
          :centered="false"
          :gap="20"
          :skeleton-nb-elements="6"
          :controls="true"
          :controls-top-offset="0.38"
          class="mb-5">
          <template #slide="{ element }: { element: any, active: boolean }">
            <ColumnEventCard
              :image="{
                src: element.image,
                alt: translate(element)?.title,
                provider: 'directus'
              }"
              :categories="element.tags"
              :title="translate(element)?.title"
              :starting-at="new Date(element.date)"
              :to="localePath({ name: 'news-id', params: { id: element.id } })"
              :author="translate(element.radio)?.title" />
          </template>
        </Carousel>
      </div>
    </Content>
    <Content class="mb-11 flex justify-center dt:mb-20">
      <PrimaryButton
        type="a"
        :to="localePath({ name: 'news' })"
        :label="t('index.our-last-news')"
        right-icon="arrow-drop-right-line" />
    </Content>
  </section>

  <section v-if="events?.length > 0">
    <h3
      class="relative z-[1] mx-auto mb-6 text-center dt:mx-6 dt:text-left"
      v-html="$t('index.our-events')" />
    <div class="relative">
      <Carousel
        :elements="events"
        :elements-per-page="1.2"
        :elements-per-page-desktop="3.5"
        :end-padding="true"
        :end-padding-desktop="true"
        :centered="false"
        :gap="20"
        :skeleton-nb-elements="6"
        :controls="true"
        :controls-top-offset="0.38"
        class="mb-5">
        <template #slide="{ element }: { element: any, active: boolean }">
          <ColumnEventCard
            :image="{
              src: element.image,
              alt: translate(element)?.title,
              provider: 'directus'
            }"
            :categories="element.tags"
            :title="translate(element)?.title"
            :starting-at="new Date(element.starting_at)"
            :ended-at="new Date(element.ended_at)"
            :to="localePath({ name: 'events-id', params: { id: element.id } })"
            :author="translate(element.radio)?.title" />
        </template>
      </Carousel>
    </div>

    <Content class="mb-11 flex justify-center dt:mb-20">
      <PrimaryButton
        type="a"
        :to="localePath({ name: 'events' })"
        :label="t('index.see-more-events')"
        right-icon="arrow-drop-right-line" />
    </Content>
  </section>

  <!-- Hidden Capsule -->
  <!-- <section>
    <h3
      class="mx-auto mb-6"
      v-html="$t('index.10-audio-capsules')" />

    <Content class="relative mb-11 overflow-hidden dt:mb-20">
      <Slider
        :items="capsules"
        class="mx-auto max-w-3xl dt:w-[calc(100%-200px)]" />
    </Content>
  </section> -->

  <NavBottom
    :tabs="[
      {
        title: $t('NavBottom.radio-boa'),
        to: localePath({ name: 'index' }),
        active: true
      },
      {
        title: $t('NavBottom.radio-partners'),
        to: localePath({ name: 'partner-radios' }),
        active: false
      }
    ]"
    class="dt:hidden" />

  <NewsletterSection />
</template>

<script lang="ts" setup>
import { translateItem } from '@/composables/use-translations'
useLocaleHeadConfig()

const localePath = useLocalePath()

const { t, locale } = useI18n()

const NB_NEWS = 3

const config = useRuntimeConfig()

const response = await useFetch('/api-corlab/home/12', {
  baseURL: config.public.apiBaseURL
})

const data = response.data.value

const promoted = data.promoted
const episodes = data.boa_moderated
const news = data.news.slice(0, NB_NEWS)
const events = data.events
const themes = data.categories

const translate = (obj) => translateItem(obj, { lang: locale.value })

const capsules = data?.capsules.map((i) => {
  return {
    id: i.id,
    image: {
      src: i.image,
      alt: translate(i)?.title,
      provider: 'directus'
    },
    title: translate(i)?.title,
    description: translate(i)?.description,
    podcast: i.podcast
  }
})
</script>

<style scoped>
#capsules {
  scroll-margin-top: 50px;
}
</style>
